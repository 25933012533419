<template>
    <TemplateHlaml>
        <template #hero>
            <InspireertHero
                title="Pagina niet gevonden"
            />
        </template>

        <template #default>
            <InspireertContentBlocksText
                :rich-text="errorMessage"
            />
        </template>
    </TemplateHlaml>
</template>

<script setup>
useHead({
    bodyAttrs: {
        'data-inspireert-hero-background': 'blue',
    },
});

const errorMessage = '<p>Deze pagina bestaat niet meer. Ga terug naar de <a href="/hetluktalleenmetlogistiek">homepagina</a> of gebruik het menu om naar een andere pagina te navigeren.</p>';
</script>
