import validate from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/pages/runtime/validate.js";
import cookie_45redirect_45global from "/builds/tln/tln-app/middleware/cookie-redirect.global.ts";
import refresh_45session_45global from "/builds/tln/tln-app/middleware/refresh-session.global.ts";
import manifest_45route_45rule from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cookie_45redirect_45global,
  refresh_45session_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/tln/tln-app/middleware/auth.ts")
}