<template>
    <section
        class="section"
        :data-width="sectionWidth"
        :data-background-style="backgroundStyle"
    >
        <div class="section__container">
            <AnchorListAnchor
                v-if="anchor"
                :id="anchor"
            />

            <div
                v-if="!!$slots.title || !!$slots.description || title || description || bodyTitle"
                class="section__header"
            >
                <div class="section__header-container">
                    <h2
                        v-if="!!$slots.title || title"
                        class="section__title"
                    >
                        <slot name="title">{{ title }}</slot>
                    </h2>

                    <div
                        v-if="!!$slots.readMore || (readMore && readMore.uri)"
                        class="section__read-more"
                    >
                        <slot name="readMore">
                            <BaseButton
                                :element="NuxtLink"
                                :to="readMore.uri"
                                icon="arrow-right"
                                icon-position="after"
                                data-size="medium"
                                class="base-button--transparent-dark"
                            >
                                {{ readMore.text ? readMore.text : 'Bekijk alles' }}
                            </BaseButton>
                        </slot>
                    </div>
                </div>

                <div
                    v-if="!!$slots.description || description"
                    class="section__description"
                >
                    <slot name="description">
                        <BaseRichText :content="description" />
                    </slot>
                </div>

                <div
                    v-if="!!$slots.callToAction"
                    class="section__call-to-action"
                >
                    <slot name="callToAction" />
                </div>

                <div
                    v-if="bodyTitle"
                    class="section__body-title"
                >
                    <h3>{{ bodyTitle }}</h3>
                </div>
            </div>

            <div class="section__body">
                <slot />
            </div>
        </div>
    </section>
</template>

<script setup>
defineProps({
    sectionWidth: {
        type: String,
        default: 'page',
        validator(value) {
            return ['page', 'content', 'content-lg', 'bleed', 'content-sm'].includes(value);
        }
    },
    backgroundStyle: {
        type: String,
        default: 'default',
        validator(value) {
            return ['default', 'lightblue', 'darkblue', 'darkerblue', 'orange', 'transparent'].includes(value);
        }
    },
    title: {
        type: String,
        default: null
    },
    bodyTitle: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    readMore: {
        type: Object,
        default: null
    },
    anchor: {
        type: String,
        default: null
    }
});

const NuxtLink = resolveComponent('NuxtLink');
</script>

<style lang="less" src="./BaseSection.less" />
