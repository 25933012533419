<template>
    <BaseSection
        v-if="title || !!$slots.default"
        section-width="bleed"
        class="cta-section hlaml-cta-section"
        :title="title"
        :description="richText"
        background-style="darkerblue"
        :data-has-body="images && images.length > 0"
    >
        <template #callToAction>
            <CraftLink
                v-if="callToAction && callToAction.url"
                :link="callToAction"
                appearance="button"
                icon="arrow-right"
                :measure-gtm-cta="true"
            >
                {{ callToAction.text }}
            </CraftLink>

            <CraftLink
                v-if="secondaryCallToAction && secondaryCallToAction.url"
                :link="secondaryCallToAction"
                class="cta-section__secondary-cta"
                appearance="button"
                icon="arrow-right"
                :measure-gtm-cta="true"
            >
                {{ secondaryCallToAction.text }}
            </CraftLink>
        </template>

        <BaseColumns
            v-if="images && images.length > 0"
            class="cta-section__images"
        >
            <template
                v-for="(image, index) in images"
                :key="`image-${index}`"
                v-slot:[`column-${index}`]
            >
                <BaseImage
                    class="cta-section__image"
                    :image="image"
                    :title="image.title"
                    :width="image.width"
                    :height="image.height"
                    :sizes="imageSizes"
                />
            </template>
        </BaseColumns>
    </BaseSection>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: null,
    },
    richText: {
        type: String,
        default: null
    },
    callToAction: {
        type: Object,
        default: null
    },
    secondaryCallToAction: {
        type: Object,
        default: null
    },
    images: {
        type: Array,
        default: null
    },
});

const imageSizes = computed(() => {
    return '(min-width: 1440px) 768px, (min-width: 768px) 768px, 520px';
});
</script>

<style lang="less">
@import '@/components/CtaSection/CtaSection.less';

.section.cta-section.hlaml-cta-section {
    --footer-image-offset: 1.5rem;
    background-color: var(--color-darkerblue);

    .section__container .section__header {
        text-align: left;
        margin-bottom: 1.5625rem;
    }

    .section__title {
        margin: 0;
    }

    .section__description {
        margin-top: 1rem;
    }

    .section__body {
        width: 100%;
        margin-top: 0;
        overflow: hidden;
    }

    .section__call-to-action {
        justify-content: start;
        margin-top: 1.5rem;
    }

    .cta-section__image {
        display: block;
        height: 10rem;
    }

    .cta-section__images {
        position: relative;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1rem;
        width: 120%;
        max-width: 75rem;
        margin-left: -10%;

        .columns__column {
            display: block;

            &:nth-child(1) {
                margin-top: calc(var(--footer-image-offset) * 2);
                // margin-bottom: calc(var(--footer-image-offset) * -2);
            }

            &:nth-child(2) {
                margin-top: var(--footer-image-offset);
                // margin-bottom: calc(var(--footer-image-offset) * -1);
            }
        }
    }

    .base-image__image {
        border-radius: .25rem;
    }

    &[data-has-body='false'] {
        padding-bottom: var(--diagonal-height);
    }

    &[data-has-body='true'] {
        padding-bottom: 3rem;

        @media @q-sm-min {
            padding-bottom: 4.5rem;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: var(--diagonal-height);
        background: var(--color-darkerblue);
        clip-path: polygon(100% 0, 100% 100%, 0 100%);
        transform: translateY(-100% + 1px);
    }

    @media @q-sm-min {
        --footer-image-offset: 3.5rem;

        .cta-section__images {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            gap: 2rem;
        }

        .section__description {
            margin-top: 2rem;
        }

        .cta-section__image {
            height: 15rem;
        }

        .section__call-to-action {
            margin-top: 2rem;
        }

        .section__container .section__header {
            margin-bottom: 4.1875rem;
        }
    }

    @media @q-md-min {
        .cta-section__image {
            height: 20rem;
        }
    }

    @media @q-lg-min {
        .cta-section__image {
            height: 25rem;
        }
    }
}
</style>
