<template>
    <DelayHydration>
        <header
            class="hero inspireert-hero"
            data-hero
            :data-background="backgroundStyle"
            v-bind="$attrs"
        >
            <div class="inspireert-hero__bg inspireert-hero__bg--desktop">
                <img
                    src="~/assets/img/inspireert/bg.png"
                    role="presentation"
                >
            </div>

            <div class="inspireert-hero__bg inspireert-hero__bg--mobile">
                <img
                    src="~/assets/img/inspireert/bg-mobile.png"
                    role="presentation"
                >
            </div>

            <div
                class="hero__top"
            >
                <slot name="top">
                    <NuxtLink
                        class="inspireert-hero__logo"
                        to="/inspireert"
                    >
                        <InspireertLogo />
                    </NuxtLink>
                </slot>
            </div>

            <div class="hero__container">
                <hgroup
                    v-if="!!props.title || !!$slots.title || !!$slots.titleElement"
                    class="hero__title"
                >
                    <slot name="titleElement">
                        <h1 v-if="props.title || !!$slots.title">
                            <slot name="title">{{ props.title }}</slot>
                        </h1>
                    </slot>
                </hgroup>

                <div class="hero__body">
                    <div
                        v-if="!!props.description || !!$slots.description"
                        class="hero__description"
                    >
                        <slot name="description">
                            <!-- eslint-disable -->
                            <div
                                v-if="props.description"
                                v-html="props.description"
                            />
                            <!-- eslint-enable -->
                        </slot>
                    </div>

                    <slot />
                </div>
            </div>
        </header>
    </DelayHydration>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    backgroundStyle: {
        type: String,
        default: 'blue',
        validator: value => ['blue', 'red'].includes(value)
    },
});
</script>

<style lang="less" src="./InspireertHero.less" />
