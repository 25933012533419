import { COOKIE_KEY_INITIAL_PAGE_PATH } from '~/constants/cookies';
import { useAuthStore } from '~/store/auth';

export default defineNuxtRouteMiddleware(() => {
    const authStore = useAuthStore();

    const initalPageCookie = useCookie(COOKIE_KEY_INITIAL_PAGE_PATH);

    if (initalPageCookie.value && authStore.loggedIn) {
        const targetPath = initalPageCookie.value;

        initalPageCookie.value = null;

        refreshCookie(COOKIE_KEY_INITIAL_PAGE_PATH);

        return navigateTo(decodeURIComponent(targetPath));
    }
});
