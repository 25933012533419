import revive_payload_client_lNppWWuti0 from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4L2ZARgmt4 from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZP0t74rDkJ from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_M4xsHiaGjc from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@5.4.14_@types+node@22.13.5_less@4.2.2_terser@5.39_rrh5emaxg5bkx3uok3enpztd7u/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_uVxPlJwg64 from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XdenGAAaQN from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HKH6Q16iFV from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eN8xYT1Cck from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/builds/tln/tln-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/tln/tln-app/.nuxt/components.plugin.mjs";
import prefetch_client_ZIqgx7U3B9 from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@22.13.5_eslint@8.57.1_ioredis@5.5.0_less@4.2.2__ygijqf4fg332kanbbp5bxu2lti/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/tln/tln-app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_TFccjdl78v from "/builds/tln/tln-app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_vite@5.4.14_@types+node_lig3d2kwcrc3ssdabssjfstp2m/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_acekdhCsIW from "/builds/tln/tln-app/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.10.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watch_ziqapdajru473qkvdilcyyg6iq/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_3JjridvAkn from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-bugsnag@7.4.0-beta.0_@bugsnag+core@7.25.0_magicast@0.3.5_vue-router@4.5.0_vue@3.5.13_typescript@5.7.3__/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import nuxt_plugin_iME3KC628D from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import axios_QMFgzss1s4 from "/builds/tln/tln-app/plugins/axios.ts";
import formie_yNFepVHNmd from "/builds/tln/tln-app/plugins/formie.js";
import gtm_cXsUZobmSF from "/builds/tln/tln-app/plugins/gtm.ts";
export default [
  revive_payload_client_lNppWWuti0,
  unhead_4L2ZARgmt4,
  router_ZP0t74rDkJ,
  _0_siteConfig_M4xsHiaGjc,
  payload_client_uVxPlJwg64,
  navigation_repaint_client_XdenGAAaQN,
  check_outdated_build_client_HKH6Q16iFV,
  chunk_reload_client_eN8xYT1Cck,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZIqgx7U3B9,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_TFccjdl78v,
  plugin_acekdhCsIW,
  plugin_3JjridvAkn,
  nuxt_plugin_iME3KC628D,
  axios_QMFgzss1s4,
  formie_yNFepVHNmd,
  gtm_cXsUZobmSF
]