import { COOKIE_KEY_AUTH_SESSION } from '~/constants/cookies';

export default async() => {
    refreshCookie(COOKIE_KEY_AUTH_SESSION);

    const { jwtDomain } = useRuntimeConfig().public;

    return await useNuxtApp().runWithContext(() => {
        return useCookie(COOKIE_KEY_AUTH_SESSION, {
            sameSite: 'strict',
            domain: jwtDomain // TODO: move to runtime config
        });
    });
};
