import { ofetch, type FetchOptions } from 'ofetch';
import { useAuthStore } from '~/store/auth';

export async function userApi(
    path: string,
    options: FetchOptions = {}
) {
    const authStore = useAuthStore();

    const { public: { laravelApiBaseURL, laravelApiKey } } = useRuntimeConfig();

    const requestHeaders: Record<string, string> = {
        'x-api-key': laravelApiKey as string,
        Accept: 'application/json'
    };

    await useNuxtApp().runWithContext(async() => {
        if (await authStore.getJwtToken) {
            requestHeaders.Authorization = `Bearer ${ await authStore.getJwtToken }`;
        }
    });

    return ofetch(
        `${laravelApiBaseURL}/${path}`,
        {
            headers: requestHeaders,
            ...options
        }
    );
}
