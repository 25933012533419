import { useAuthStore } from '~/store/auth';
import { useDossierStore } from '~/store/dossiers';
import { useGtm } from '@gtm-support/vue-gtm';

export type UserFunction = {
    id: string;
    organization_id: string;
    organization_name: string;
    function: string;
    email: string;
    phone_landline: string;
    phone_mobile: string;
    standard_function: boolean;
    profile_manager: boolean;
};

export type User = {
    id: number;
    windex_user_id: string | null;
    prefix: string | null;
    first_name: string | null;
    last_name: string | null;
    full_name: string | null;
    birthdate: string | null;
    gender: string | null;
    email: string;
    functions: UserFunction[];
    content_permissions: {
        fenex: boolean;
        tln: boolean;
        tln_international: boolean;
    };
    following: number[];
    dossier_notification_frequency: 'none' | 'daily' | 'weekly';
};

class UserModel {
    id: number;
    windexUserId: string | null;
    prefix: string | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    birthdate: string | null;
    gender: string | null;
    email: string;
    functions: UserFunction[];
    contentPermissions: {
        fenex: boolean;
        tln: boolean;
        tln_international: boolean;
    };
    following: number[];
    dossierNotificationFrequency: 'none' | 'daily' | 'weekly';

    companyName: string | null;
    profileManager: boolean;

    constructor(userDataResponse: User) {
        this.id = userDataResponse.id;
        this.windexUserId = userDataResponse.windex_user_id;
        this.prefix = userDataResponse.prefix;
        this.firstName = userDataResponse.first_name;
        this.lastName = userDataResponse.last_name;
        this.fullName = userDataResponse.full_name;
        this.email = userDataResponse.email;
        this.functions = userDataResponse.functions;
        this.birthdate = userDataResponse.birthdate;
        this.gender = userDataResponse.gender;
        this.following = userDataResponse.following;
        this.contentPermissions = userDataResponse.content_permissions;
        this.dossierNotificationFrequency = userDataResponse.dossier_notification_frequency;

        // Computed data
        this.companyName = this.getCompanyName();
        this.profileManager = this.isProfileManager();
    }

    getCompanyName() {
        const primaryFunction = this.getPrimaryFunction();

        if (!primaryFunction) {
            return null;
        }

        return primaryFunction.function;
    }

    getPrimaryFunction() {
        if (!this.functions || !this.functions.length) {
            return;
        }

        return this.functions.find(userFn => userFn.standard_function);
    }

    isProfileManager() {
        if (!this.functions || !this.functions.length) {
            return false;
        }

        return !!this.functions.find(userFn => userFn.profile_manager === true);
    }

    canManageOrganization(organizationId: string) {
        if (!this.functions || !this.functions.length) {
            return;
        }

        const targetfunction = this.functions.find((userFn) => {
            return userFn.organization_id === organizationId;
        });

        if (!targetfunction) {
            return false;
        }

        return targetfunction.profile_manager === true;
    }

    isFollowingDossier(dossierId: string) {
        return toValue(this.following).includes(parseInt(dossierId, 10));
    }

    addDossierIds(ids: number[]) {
        ids.forEach(id => {
            if (toValue(this.following).includes(id)) {
                return;
            }

            this.following.push(id);

            try {
                useGtm()?.trackEvent({
                    event: 'dossier_follow',
                    dossier: useDossierStore()?.dossierById(id)?.title,
                    dossierId: id
                });
            } catch {
                // do nothing
            }
        });
    }

    removeDossierIds(ids: number[]) {
        this.following.forEach((dossierId, index) => {
            if (!ids.includes(dossierId)) {
                return;
            }

            this.following.splice(index, 1);

            const dossier = useDossierStore()?.dossierById(dossierId);

            try {
                useGtm()?.trackEvent({
                    event: 'dossier_unfollow',
                    dossier: dossier?.title,
                    dossierId: dossierId
                });
            } catch {
                // do nothing
            }
        });
    }

    async followDossiers(payload: number[]) {
        const authStore = useAuthStore();

        const promises: Promise<number>[] = [];

        payload.forEach(dossierId => {
            promises.push(new Promise(async(resolve, reject) => { /* eslint-disable-line no-async-promise-executor */
                const data = await userApi(
                    `user/follows-topic/${dossierId}`,
                    {
                        method: 'POST'
                    }
                );

                if (data.error) {
                    reject(data.error);
                }

                // IDs from the API can be strings or integers
                resolve(parseInt(data?.topic_id, 10));
            }));
        });

        await Promise.all(promises).then(async(followedIds: number[]) => {
            this.addDossierIds(followedIds);

            await authStore.fetchUser();
        }).catch(error => {
            console.error(error);
        });
    }

    async unfollowDossiers(payload: number[]) {
        const authStore = useAuthStore();

        const promises: Promise<number>[] = [];

        payload.forEach(dossierId => {
            promises.push(new Promise(async(resolve, reject) => { /* eslint-disable-line no-async-promise-executor */
                const data = await userApi(
                    `user/follows-topic/${dossierId}`,
                    {
                        method: 'DELETE'
                    }
                );

                if (data.error) {
                    reject(data.error);
                }

                // IDs from the API can be strings or integers
                resolve(parseInt(data, 10));
            }));
        });

        await Promise.all(promises).then(async(unfollowedIds: number[]) => {
            this.removeDossierIds(unfollowedIds);

            await authStore.fetchUser();
        }).catch(error => {
            console.error(error);
        });
    }

    async setDossierUpdateFrequency(frequency: string) {
        const authStore = useAuthStore();

        await userApi(`user/preferences/dossier-updates/${frequency}`, {
            method: 'POST',
        });

        await authStore.fetchUser();
    }
}

export default UserModel;
