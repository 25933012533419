export default (link) => {
    if (!link) {
        return undefined;
    }

    let uri = null;

    if (link?.element?.uri) {
        uri = link.element.uri;
    }

    else if (link?.url) {
        // URL is absolute, so return it unchanged
        return link.url;
    }

    if (uri === HOME_URI) {
        uri = '/';
    }

    if (!uri) {
        return undefined;
    }

    // Strip any leading slashes
    uri = uri.replace(/^\/+/g, '');

    return {
        path: `/${uri}`
    };
};
