<template>
    <NuxtLayout
        :name="layoutName"
    >
        <HlamlErrorPage
            v-if="layoutName === 'hlaml'"
            :error="error"
        />

        <InspireertErrorPage
            v-else-if="layoutName === 'inspireert'"
            :error="error"
        />

        <ErrorPage
            v-else
            :error="error"
        />
    </NuxtLayout>
</template>

<script setup>
import ErrorPage from '~/components/ErrorPage/ErrorPage.vue';
import HlamlErrorPage from '~/components/Hlaml/ErrorPage/HlamlErrorPage.vue';
import InspireertErrorPage from '~/components/Inspireert/ErrorPage/InspireertErrorPage.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const layoutName = computed(() => {
    if (route.meta.layout === 'hlaml') {
        return 'hlaml';
    }

    if (route.meta.layout === 'inspireert') {
        return 'inspireert';
    }

    return 'default';
});
</script>
