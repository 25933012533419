import { useAuthStore } from '~/store/auth';

const SKIP_PATHS = [
    '/mijn-tln/profiel',
    '/mijn-tln/dossiers',
    '/mijn-tln/organisaties',
];

export default defineNuxtRouteMiddleware(async(to) => {
    const authStore = useAuthStore();

    const jwtTokenCookie = await useJwtToken();

    if (
        !SKIP_PATHS.find((skipPath) => to.path.startsWith(skipPath))
        && jwtTokenCookie.value
        && !authStore.user
    ) {
        const response = await authStore.refreshJwtToken();

        if (response?.success && response?.token) {
            jwtTokenCookie.value = response.token;
            authStore.jwtToken = response.token;

            await authStore.fetchUser();
        } else if (jwtTokenCookie.value || !response) {
            jwtTokenCookie.value = null;
        }
    }
});
